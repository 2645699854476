<template>
  <div>
    <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :templateContent="{ decodeType: this.forWhat, ...result }"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="true"
      :addable="true"
      addButtTitle="Add Attribute"
      :isValid="isValid"
      :linePrefix="'explorerGroupEval_' + index"
      :hideDetails="hideDetails"
    ></lineFieldsArray>
  </div>
</template>
<script>
import options from "@/cfg/options.json";
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";

import lineButtons from "@/cfg/lineButtons.json";
export default {
  components: { lineFieldsArray },
  data: function () {
    return {
      pairLength: 0,
    };
  },
  props: {
    // value: {},
    //addable: { type: Boolean, default: true },
    //headerTitle: { type: Boolean },
    operatorType: {},
    forWhat: String,

    //correctOperators: { type: Boolean, default: true },
    value: {
      default() {
        return {};
      },
    },
    result: {},
    isValid: { type: Boolean },
    linePrefix: { type: String, default: "" },
    hideDetails: { type: Boolean, default: false },
    connectorsArray: {},
    index: {},
  },
  computed: {
    fields() {
      let fields = {
        type: {
          type: "select",
          name: "Type",
          associatedOption: [
            ["ok", "OK"],
            ["warning", "Warning"],
            ["error", "Error"],
          ],
          fieldAttrInput: { class: "required" },
        },
        operator: {
          type: "select",
          associatedOption: options.correctOperators,
          name: "Operator",
          fieldAttrInput: { class: "required" },
        },
        content: {
          inputFieldFormatter: "evalContentFormatter",
          type: "text",

          name: "Value",
          fieldAttrInput: { class: "required" },
        },
        else: {
          input: false,
          type: "string",
          string: "else",
          colAttrs: {
            style: "max-width: 27px;",
          },
        },
        actionType: {
          type: "select",
          associatedOption: [["connector", "Connector"]],
          name: "Result",
          fieldAttrInput: { class: "required" },
          default: "error",
        },
        action: {
          type: "select",
          associatedOption: this.connectorsArray,
          name: "Action",
          default: "stopTest",
          fieldAttrInput: { class: "required" },
        },
        remove: lineButtons.remove,
      };
      if (this.type == "expect") {
        this.$delete(fields, "else");
        this.$delete(fields, "result");
        this.$delete(fields, "action");
      }
      return fields;
    },
    pairArray: {
      get() {
        let pairArray = [];
        if (typeof this.value == "object" && Array.isArray(this.value)) {
          pairArray = this.value;
        }

        return pairArray;
      },
      // set(newValue) {
      //     console.log("new value",newValue);
      // }
    },
  },
  methods: {
    checkInput(newValueArray) {
      this.$emit("input", newValueArray);
    },
  },
};
</script>