var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2",staticStyle:{"position":"relative","z-index":"0"}},[(_vm.valueLine.subscriberType == 'explorer')?_c('div',[_c('v-expansion-panels',{attrs:{"hover":"","tile":"","focusable":"","id":"groupAction"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"stepHeadColor subtitle-2",attrs:{"id":"testGroupActions","color":"white&#45;&#45;black"}},[_vm._v("Configure Test Group Actions")]),_c('v-expansion-panel-content',[_c('div',{staticClass:"mt-3 mb-3"},_vm._l((_vm.localvalue.testgroup),function(group,index){return _c('v-card',{key:index,staticClass:"mb-1"},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('associatedOption',{attrs:{"fieldAttributes":{
                        name: 'Action',
                        associatedOption: [
                          ['explorerCsv', 'Generate Explorer CSV Report'],
                          ['checkGroupResult', 'Monitor Status Threshold'],
                        ],
                      },"type":"select","fieldAttrInput":{
                        class: 'required',
                        id: 'action_' + index,
                      },"templateContent":_vm.result,"isValid":_vm.isValid,"field":"protocol"},model:{value:(group.action),callback:function ($$v) {_vm.$set(group, "action", $$v)},expression:"group.action"}})],1),_c('v-col',{staticClass:"pt-5"},[_c('trash',{attrs:{"result":_vm.result},on:{"click":function($event){return _vm.removeLine(index)}}})],1)],1),(group.action == 'checkGroupResult')?_c('explorerGroupEval',{attrs:{"isValid":_vm.isValid,"connectorsArray":_vm.result.additional.connectorsArray,"index":index,"result":_vm.result},model:{value:(group.eval),callback:function ($$v) {_vm.$set(group, "eval", $$v)},expression:"group.eval"}}):_vm._e()],1)],1)}),1),_c('primaryButton',{attrs:{"type":"button","label":"Add Action","icon":"mdi-check","color":"primary","id":"addAction","disabled":_vm.disabled},on:{"click":function($event){return _vm.addAction()}}})],1)],1)],1)],1):_c('v-expansion-panels',{attrs:{"hover":"","tile":"","focusable":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"stepHeadColor subtitle-2",attrs:{"color":"white--black"}},[_vm._v(_vm._s(_vm.languagePack("tests", "connectors")))]),_c('v-expansion-panel-content',_vm._l((_vm.conditions),function(condition){return _c('v-row',{key:condition,staticClass:"mt-3"},[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"1"}},[_vm._v(_vm._s(_vm.mappingArray[condition]))]),_c('v-col',{staticClass:"rootConnBuild"},[_c('lineFieldsArray',{attrs:{"fieldAttrRow":{},"fields":_vm.fields,"value":_vm.getValue(condition),"headerTitle":false,"addable":true,"isValid":_vm.isValid,"templateContent":_vm.result},on:{"line-field-input":function($event){return _vm.checkInput(condition, ...arguments)},"revalidate":function($event){return _vm.$emit('revalidate')}}})],1)],1)}),1)],1)],1),_c('div',{staticClass:"pb-3"}),_c('input',{attrs:{"type":"hidden","name":_vm.field},domProps:{"value":JSON.stringify(_vm.localvalue)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }